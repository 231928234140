<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card
          no-body
          class="mb-1"
        >
          <div class="d-flex justify-content-between align-items-center px-1 py-1">
          <div>
              <h3 class="m-0 py-1">
                {{ $t("Categorías") }}
              </h3>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <b-link v-if="role === 'super_admin' || role === 'admin_empresa'" :to="{ name: 'createCategory' }" >
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1 text-nowrap"
                  >
                    {{ $t("Añadir categoría") }}
                  </b-button>
                </b-link>
              </div>
              <span
                  class="ml-1 cursor-pointer"
                  @click="visibleFilter = !visibleFilter"
              >
              <feather-icon
                  icon="FilterIcon"
                  size="20"
              />
            </span>
            </div>
          </div>
          <div>
            <b-collapse
              id="collapse"
              v-model="visibleFilter"
              class="mt-2"
            >
              <div class="px-2">
                <b-row>
                  <b-col
                      sm="4"
                      style="padding-right: 0px"
                  >
                    <b-form-group
                        :label="$t('Busqueda')"
                        label-for="filter-search"
                    >
                      <b-row>
                        <b-col sm="8">
                          <b-form-input
                              v-model="searchTerm"
                              :placeholder="$t('Busqueda')"
                              type="text"
                              class="d-inline-block"
                          />
                        </b-col>
                        <b-col
                            sm="4"
                            style="padding-left: 0px"
                        >
                          <b-button
                              variant="primary"
                              @click="handleSearch"
                          >
                            {{ $t("Buscar") }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="$t('Familia de categorías')"
                      label-for="filter-category"
                    >
                      <SelectCategories v-model="category" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" v-if="selectTypes.length > 1" >
                    <b-form-group
                      :label="$t('Tipo')"
                      label-for="filter-type"
                    >
                      <v-select
                          v-model="type"
                          label="name"
                          :filterable="true"
                          :searchable="true"
                          :options="selectTypes"
                          :placeholder="$t('Tipo')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
          >
            <template #cell(parent)="data">
              <span v-if="data.item.parent">
                {{ data.item.parent.name }}
              </span>
            </template>
            <template #cell(type)="data">
              <span v-if="data.item.type">
                {{ $i18n.t(data.item.type) }}
              </span>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'editCategory', params: { id: data.item.id } }"
                  class="mr-1"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    size="16"
                  />
                </b-link>
                <span
                    v-if="role === 'super_admin' || role === 'admin_empresa'"
                    class="cursor-pointer text-danger"
                  @click="deleteCategory(data.item.id, data.item.name)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                  />
                </span>
              </span>
            </template>
          </b-table>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="ml-50 mr-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              {{ $t("Total") }}: {{ totalItems }}</span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BPagination,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BCol,
  BRow,
  BLink,
  BFormGroup,
  BCollapse, BFormInput,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import vSelect from 'vue-select'
import SelectCategories from '@/components/selectCategories/SelectCategories.vue'

export default {
  components: {
    BFormInput,
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormSelect,
    BFormGroup,
    BCollapse,
    SelectCategories,
    vSelect
  },
  data() {
    return {
      log: [],
      visibleFilter: false,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      checkAllProducts: false,
      client: {},
      category: '',
      type: '',
      pages: ['10', '15', '25'],
      columns: [
        {
          label: this.$t('Nombre'),
          key: 'name',
        },
        {
          label: this.$t('Padre'),
          key: 'parent',
        },
        {
          label: this.$t('Tipo'),
          key: 'type',
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'text-right',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters({
      currentCompany: 'companies/getCurrentCompany',
      items: 'categories/getItems',
      totalItems: 'categories/getTotalItems',
      currentClient: 'clients/getCurrentClient',
      listClients: 'clients/getItems',
      selectTypes: 'clients/getSelectTypesAvailables',
      role: 'auth/getRole',
      user: 'auth/getUser',
    }),
    statusVariant() {
      const statusColor = {
        1: 'light-success',
        0: 'light-danger',
      }

      return status => statusColor[status]
    },
    statusVariantText() {
      const statusText = {
        1: 'Activo',
        0: 'Desactivado',
      }

      return status => statusText[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  watch: {
    client() {
      this.chargeData()
    },
    checkAllProducts() {
      this.chargeData()
    },
    category() {
      this.currentPage = 1
      this.chargeData()
    },
    type() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  created() {
    this.getListClients({
      company: this.currentCompany.id,
      page: 1,
      per_page: 999999,
      search: '',
    })
    this.chargeData()
  },
  methods: {
    ...mapActions({
      list: 'categories/getList',
      getListClients: 'clients/getListClients',
      delete: 'categories/delete',
    }),
    canEdit(client_id) {
      let ifedit = false
      if ((this.role === 'admin_cliente' && client_id === this.user.clients[0].id)
          || (this.role === 'admin_empresa' && client_id === null)
          || this.role === 'super_admin') {
        ifedit = true
      }
      return ifedit
    },
    chargeData() {
      let cliente = this.currentClient ? this.currentClient.id : ''
      if (this.role !== 'admin_cliente' && cliente === '') {
        cliente = this.client ? this.client.id : ''
      }
      if (this.role === 'admin_cliente' && this.checkAllProducts) {
        cliente = ''
      }
      const category = this.category ? this.category.id : ''
      const type = this.type ? this.type.value : ''

      this.list({
        company: this.currentCompany.id,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        type,
        parent_id: category,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteCategory(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t('EliminarConfirm', { nombre: name }), {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
